<template>
  <v-container class="pa-0">
    <v-row>
      <v-col cols="4">
        <span class="font-weight-medium">{{ titleFile }}</span>
      </v-col>
      <v-col class="d-flex">
        <v-row
          class="my-auto"
          align="center"
        >
          <div class="col ma-0 pa-0 text-center">
            <v-tooltip
              :disabled="!isDecryptedFile && isProtectedFile"
              top
            >
              <template v-slot:activator="{ on }">
                <a
                  :class="`${disabledDownload ? 'grey--text cursor-disabled' : 'info--text'} font-weight-bold`"
                  @click="downloadForm"
                  v-on="on"
                >
                  {{ clearFilename }}
                </a>
              </template>
              <span>{{ inProgressDownload ? $t('download_in_progress') : $t('download_file') }}</span>
            </v-tooltip>
          </div>
          <div
            v-if="isProtectedFile"
            class="col col-2 text-center"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  :color="isDecryptedFile ? 'success' : 'warning'"
                  v-on="on"
                >
                  {{ isDecryptedFile ? 'fa-unlock' : 'fa-lock' }}
                </v-icon>
              </template>
              <span>{{ (!isDecryptedFile && isProtectedFile) ? $t('encrypted_file') : $t('decrypted_file') }}</span>
            </v-tooltip>
          </div>
          <div class="col col-2 text-center">
            <v-progress-circular
              v-if="inProgressDownload"
              :size="30"
              color="green"
              indeterminate
            />
          </div>
        </v-row>
      </v-col>
      <v-col
        class="col-2 d-flex"
      >
        <span class="ma-auto">{{ fileSize }}</span>
      </v-col>
      <v-col
        v-if="isProtectedFile"
        class="col-2 d-flex"
      >
        <v-card-actions class="justify-center align-center ma-auto">
          <v-btn
            class="pr-0-0-0"
            color="primary secondary--text"
            large
            :disabled="isDecryptedFile"
            @click="() => { showConfirmateRequest = true }"
          >
            <h5>{{ $t('request_opening') }}</h5>
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
    <confirmation-modal
      :show-modal="showConfirmateRequest"
      :title-modal="$t('modals.confirm_request.title')"
      :message-modal="$t('modals.confirm_request.message')"
      :agree-text-bottom="$t('modals.confirm_request.btn_agree')"
      :disagree-text-bottom="$t('modals.confirm_request.btn_disagree')"
      @resolve="resolveConfirmateRequestModal"
    />
    <confirmation-modal
      :show-modal="showConfirmateSave"
      :title-modal="$t('modals.request_made.title')"
      :message-modal="$t('modals.request_made.message')"
      :agree-text-bottom="$t('modals.request_made.btn_agree')"
      :show-disagree-button="false"
      icon="far fa-check-circle"
      icon-color="success"
      @resolve="showConfirmateSave = false"
    />
    <v-divider class="my-3" />
  </v-container>
</template>

<script>
import { GET_DECRYPT_FILE, URL_DOWNLOAD_FILE, BYTES_IN_A_KILOBYTE } from './data/fileInfoData'
import { BAD_REQUEST } from '@/shared/constants/httpStatus'
import { SHOW_ALERT, ADD_COUNTER_REQUEST, SUBTRACT_COUNTER_REQUEST } from '@/store/mutations-types'
import ConfirmationModal from '@/components/uiComponents/modals/confirmationModal/ConfirmationModal'
import handleErrorMixin from '@/mixins/handleErrorMixin'
import fileSaver from 'file-saver'

export default {
  name: 'FileInfo',
  components: {
    ConfirmationModal
  },
  mixins: [
    handleErrorMixin
  ],
  props: {
    fileInfo: {
      type: Object,
      required: true,
      description: 'Informacion del archivo'
    },
    titleFile: {
      type: String,
      required: true,
      description: 'Titulo del archivo'
    },
    descriptionFile: {
      type: String,
      default: null,
      description: 'Descripcion del archivo'
    },
    proposalId: {
      type: Number,
      required: true,
      decription: 'Id de la propuesta a la que pertenece el archivo'
    },
    isProtectedFile: {
      type: Boolean,
      default: false,
      description: 'Determina si el archivo hace parte de los documentos cifrados'
    },
    stageId: {
      type: Number,
      required: true,
      decription: 'Id de etapa'
    }
  },
  data () {
    return {
      inProgressDownload: false,
      showConfirmateSave: false,
      showConfirmateRequest: false
    }
  },
  computed: {
    /**
     *
     * @author Juan P. Bolivar
     * @version 1.0.0
     */
    fileSize () {
      let kb = this.fileInfo.fileSize / BYTES_IN_A_KILOBYTE
      return `${Math.trunc(kb) + (kb % 1 !== 0 ? 1 : 0)} kB`
    },
    /**
     *
     * @author Juan P. Bolivar
     * @version 1.0.0
     */
    isDecryptedFile () {
      return !this.fileInfo.fileEncryptStatus
    },
    /**
     *
     * @author Juan P. Bolivar
     * @version 1.0.0
     */
    clearFilename () {
      return this.fileInfo.fileName
    },
    /**
     *
     * @author Juan P. Bolivar
     * @version 1.0.0
     */
    disabledDownload () {
      return this.inProgressDownload || (!this.isDecryptedFile && this.isProtectedFile)
    }
  },
  methods: {
    /**
     *
     * @author Andres Correa
     * @since 26/05/2020 11:50 AM
     * @version 1.0.0
     */
    requestDecrypt () {
      this.$store.commit(ADD_COUNTER_REQUEST)
      this.axios.post(GET_DECRYPT_FILE, {
        stageId: this.stageId,
        tenderUserProposalId: this.proposalId,
        documentId: this.fileInfo.id
      }).then((response) => {
        this.showConfirmateSave = true
      }).catch((error) => {
        if (error.response.status === BAD_REQUEST) {
          this.$store.commit(SHOW_ALERT, {
            type: 'warning',
            text: error.response.data.errorMessage,
            show: true
          })
          return
        }
        this.$_handleErrorMixin_generateError(error)
      }).finally(() => {
        this.$store.commit(SUBTRACT_COUNTER_REQUEST)
      })
    },
    /**
     * This method get the file document to download
     *
     * @author Andres Correa
     * @since 26/05/2020 2:50 PM
     * @version 1.0.0
     */
    downloadForm () {
      if (this.disabledDownload) return
      this.inProgressDownload = true
      this.axios.get(URL_DOWNLOAD_FILE, {
        params: {
          documentId: this.fileInfo.id,
          tenderProposalId: this.proposalId
        },
        responseType: 'blob'
      }).then((response) => {
        fileSaver.saveAs(response.data, this.clearFilename)
        this.$store.commit(SHOW_ALERT, {
          type: 'success',
          text: this.$t('alert_messages.downloaded_file').replace('{filename}', this.clearFilename),
          show: true
        })
      }).catch((error) => {
        if (error.response.status === BAD_REQUEST) {
          this.$store.commit(SHOW_ALERT, {
            type: 'warning',
            text: this.$t('alert_messages.not_found_file').replace('{filename}', this.clearFilename),
            show: true
          })
          return
        }
        this.$_handleErrorMixin_generateError(error)
      }).finally(() => {
        this.inProgressDownload = false
      })
    },
    resolveConfirmateRequestModal (value) {
      if (value) {
        this.requestDecrypt()
      }
      this.showConfirmateRequest = false
    }
  }
}
</script>

<i18n>
{
  "es": {
    "request_opening": "Solicitar apertura",
    "download_file": "Descargar archivo",
    "encrypted_file" : "Archivo cifrado",
    "download_in_progress": "Descarga en curso",
    "decrypted_file": "Archivo descifrado",
    "typeFiles": {
      "DFD" : {
        "title": "FORMULARIO TEC-1",
        "subtitle": "Formulario de presentación de propuesta técnica"
      },
      "OMD" : {
        "title": "GARANTÍA DE MANTENIMIENTO DE LA OFERTA",
        "subtitle": "Garantía de la oferta"
      },
      "AAL" : {
        "title": "FORMULARIO ELEG-I",
        "subtitle": "Carta de aceptación de las guías de anticorrupción y marco de sanciones del Banco Mundial"
      },
      "IDD" : {
        "title": "FORMULARIO ELEG II",
        "subtitle": "Declaración de integridad"
      },
      "APCA" : {
        "title": "FORMULARIO ELEG III",
        "subtitle": "Modelo de carta de conformación de Apca"
      },
      "LCA" : {
        "title": "CAPACIDAD JURÍDICA",
        "subtitle": "Capacidad jurídica y representación legal"
      },
      "TEC2A" : {
        "title": "FORMULARIO TEC-2A",
        "subtitle": "Organización y experiencia del consultor Organización del Consultor"
      },
      "TEC2B" : {
        "title": "FORMULARIO TEC-2B",
        "subtitle": "Organización y experiencia del consultor Experiencia específica del consultor"
      },
      "TEC4" : {
        "title": "FORMULARIO TEC-4",
        "subtitle": "Descripción de la metodología, enfoque y organización para la ejecución del contrato de interventoría"
      },
      "TEC5" : {
        "title": "FORMULARIO TEC-5",
        "subtitle": "Composición del equipo y asignación de responsabilidades"
      },
      "TEC6" : {
        "title": "FORMULARIO TEC-6",
        "subtitle": "Currículo del personal profesional clave propuesto"
      },
      "EID" : {
        "title": "INFORMACIÓN FINANCIERA",
        "subtitle": "Opcional"
      },
      "EPD" : {
        "title": "FORMULARIO PR-1",
        "subtitle": "Formulario de presentación de la propuesta de precio"
      }
    },
    "alert_messages": {
      "downloaded_file": "El archivo {filename} se ha descargado con exito",
      "not_found_file": "El archivo {filename} no se ha podido descargar"
    },
    "modals": {
      "request_made": {
        "title": "SOLICITUD REALIZADA",
        "message": "Se ha solicitado al proponente la apertura del archivo cifrado",
        "btn_agree": "Entendido"
      },
      "confirm_request": {
        "title": "CONFIRMACION DE SOLICITUD",
        "message": "¿Está seguro que desea solicitar al proponente el descifrado del archivo?",
        "btn_agree": "Continuar",
        "btn_disagree": "Cancelar"
      }
    }
  }
}
</i18n>

<style lang="scss">
.cursor-disabled {
  cursor: default !important;
}
</style>
