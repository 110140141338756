
const PUBLIC_BASE_URL = process.env.VUE_APP_API_URL

export const URL_UPLOAD_PDF = `${PUBLIC_BASE_URL}service/proposal/file`
export const GET_DETAILS_PROPOSAL = `${PUBLIC_BASE_URL}service/proposal/tender/detail`
export const GET_DECRYPT_FILE = `${PUBLIC_BASE_URL}service/encryption/request-decrypt-file`

export const DECRYPTED_FILE_DOCUMENT_TYPE_PREFIX = 'D-'

export const PRESENTATION = [
  'DFD',
  'OMD',
  'AAL',
  'IDD',
  'APCA',
  'LCA'
]

export const TECHNICAL = [
  'TEC2A',
  'TEC2B',
  'TEC4',
  'TEC5',
  'TEC6',
  'EID'
]

export const ECONOMIC = [
  'D-EPD',
  'EPD'
]
