<template>
  <v-container>
    <v-row class="pt-7">
      <v-col>
        <h1 class="headline font-weight-bold text-uppercase">
          {{ finishDetail.tenderName }}
        </h1>
      </v-col>
      <v-col align="right">
        <time-remaining
          v-if="Object.keys(finishDetail).lenght !== 0"
          :end-time="finishDetail.tenderDueDate"
        />
      </v-col>
    </v-row>
    <v-divider class="my-3 primary" />
    <v-row no-gutters>
      <v-col>
        <h2 class="headline text-uppercase grey--text">
          {{ $t('basic_data') }}
        </h2>
      </v-col>
    </v-row>
    <v-divider class="my-3" />
    <v-row>
      <v-col
        cols="12"
        lg="9"
      >
        <v-container class="py-0">
          <v-row class="mb-3">
            <span class="col col-12 col-md-2 pa-0 grey--text">
              {{ $t('contractor') }}:
            </span>
            <span
              class="col col-12 col-md-10 pa-0"
            >
              {{ finishDetail.tenderOwner }}
            </span>
          </v-row>
          <v-row>
            <span class="col col-12 col-md-2 pa-0 grey--text">
              {{ $t('object_to_contract') }}:
            </span>
            <span class="col col-12 col-md-10 pa-0">
              {{ finishDetail.tenderDescription }}
            </span>
          </v-row>
        </v-container>
      </v-col>
      <v-col
        class="col-12 col-lg-3">
        <v-container class="py-0 d-flex d-lg-block">
          <v-row class="mb-lg-3">
            <span class="col col-12 col-sm-6 col-lg-6 pa-0 grey--text text-lg-right">
              {{ $t('close_date') }}:
            </span>
            <span class="col col-12 col-md-6 pa-0 my-auto text-lg-right">
              {{ $_formatDateMixin_formatDate(finishDetail.tenderDueDate) }}
            </span>
          </v-row>
          <v-row>
            <span class="col col-12 col-sm-6 col-lg-6 pa-0 grey--text text-lg-right">
              {{ $t('close_time') }}:
            </span>
            <span class="col col-12 col-md-6 pa-0 my-auto text-lg-right">
              {{ $_formatDateMixin_formatTime(finishDetail.tenderDueDate) }}
            </span>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="headline text-uppercase grey--text">
          {{ $t('data_proponent') }}
        </h2>
        <v-divider class="my-3" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="py-0"
        cols="12"
        md="6"
      >
        <v-row class="d-md-flex">
          <v-col class="col-3 pt-0 grey--text">
            {{ $t('name_proponent') }}:
          </v-col>
          <v-col class="py-0">
            {{ finishDetail.proposalName }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-3 py-0 grey--text">
            {{ $t('members') }}:
          </v-col>
          <v-col class="py-0">
            <p
              v-for="(member, index) in finishDetail.members"
              :key="index"
              class="mb-3 pb-5"
            >
              {{ member }}
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="this.userInfo.profileCode && this.userInfo.profileCode !== 'SU'"
        class="py-0"
        cols="12"
        md="6"
      >
        <v-row>
          <v-col class="pt-0 grey--text">
            {{ $t('proposed_submission_date') }}:
          </v-col>
          <v-col class="col-5 py-0">
            {{ $_formatDateMixin_formatDate(finishDetail.proposalFinishedDate) }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 grey--text">
            {{ $t('proposed_submission_time') }}:
          </v-col>
          <v-col class="col-5 py-0">
            {{ $_formatDateMixin_formatTime(finishDetail.proposalFinishedDate) }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider class="my-3" />
    <v-row
      v-if="finishDetail !== {}"
    >
      <v-col
        v-if="finishDetail.stageResponseList && finishDetail.stageResponseList.length > 0"
        cols="12"
      >
        <v-col
          v-for="stagesDetail in finishDetail.stageResponseList"
          :key="stagesDetail.id"
          cols="12"
        >
          <v-list-item-title class="headline mb-1">
            Etapa: {{ stagesDetail.name }}
          </v-list-item-title>
          <v-col
            v-for="sectionsDetails in stagesDetail.sectionResponseList"
            :key="sectionsDetails.id"
            cols="12"
          >
            <h3>Sección: {{ sectionsDetails.name }}</h3>
            <v-col
              v-for="document in sectionsDetails.documentResponseList"
              :key="document.id"
              class="d-flex"
              cols="12"
              md="6"
              lg="10"
            >
              <file-info
                :file-info="document"
                :title-file="document.name"
                :proposal-id="proposalId"
                :is-protected-file="document.encrypted"
                :stage-id="stagesDetail.id"
              />
            </v-col>
          </v-col>
        </v-col>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-end">
      <v-btn
        color="secondary lighten-1 white--text"
        class="mt-12 mb-4 mr-4"
        @click="toReturn"
      >
        {{ $t('return') }}
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import Vue from 'vue'
import store from '@/store'
import { GET_DETAILS_PROPOSAL } from './data/proposalFinished'
import { ADD_COUNTER_REQUEST, SUBTRACT_COUNTER_REQUEST } from '@/store/mutations-types'
import TimeRemaining from '@/components/uiComponents/timeRemaining/TimeRemaining'
import FileInfo from '@/components/uiComponents/fileInfo/FileInfo'
import handleErrorMixin from '@/mixins/handleErrorMixin'
import FormatDateMixin from '@/mixins/formatDateMixin'

export default {
  name: 'ProjectFinishDetail',
  components: {
    TimeRemaining,
    FileInfo
  },
  mixins: [
    handleErrorMixin,
    FormatDateMixin
  ],
  beforeRouteEnter (to, from, next) {
    if (!to.params.tenderId) {
      next({ name: 'ProjectsReceived' })
    } else {
      store.commit(ADD_COUNTER_REQUEST)
      let params = {
        params: {
          tenderProposalId: parseInt(to.params.proposalId)
        }
      }
      Vue.axios
        .get(`${GET_DETAILS_PROPOSAL}`, params)
        .then(response => {
          store.commit(SUBTRACT_COUNTER_REQUEST)
          next(vm => { vm.finishDetail = response.data })
        })
        .catch(error => {
          if (error.response) {
            store.commit(SUBTRACT_COUNTER_REQUEST)
            next(vm => vm.$_handleErrorMixin_generateError(error))
          }
        })
    }
  },
  data () {
    return {
      finishDetail: {},
      decryptedFiles: [],
      userInfo: this.$store.state.security.userInfo
    }
  },
  computed: {
    proposalId () {
      return this.$route.params.proposalId
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (Object.keys(this.finishDetail).length === 0) {
        this.toReturn()
      }
    })
  },
  methods: {
    /**
     * This method return the after view
     *
     * @author Andres Correa
     * @since 26/05/2020 11:50 AM
     * @version 1.0.0
     */
    toReturn () {
      this.$router.push({
        name: 'ProjectsReceived',
        params: { tenderId: this.$route.params.tenderId }
      })
    }
  }
}
</script>
<i18n>
{
  "es": {
    "basic_data": "Datos básicos proyecto",
    "contractor": "CONTRATANTE",
    "object_to_contract": "OBJETO A CONTRATAR",
    "close_date": "FECHA DE VISUALIZACIÓN",
    "close_time": "HORA DE VISUALIZACIÓN",
    "data_proponent": "DATOS PROPONENTE",
    "name_proponent": "NOMBRE",
    "members": "INTEGRANTES",
    "proposed_submission_date": "FECHA PRESENTACIÓN PROPUESTA",
    "proposed_submission_time": "TIEMPO PRESENTACIÓN PROPUESTA",
    "form_submission": "FORMULARIOS GENERALES Y DE ELEGIBILIDAD",
    "technical_proposal": "PROPUESTA TÉCNICA",
    "price_proposal": "PROPUESTA PRECIO",
    "return": "REGRESAR"
  }
}
</i18n>
